<template>

    <div style="margin:50px auto;display:block;">

        <v-layout row justify-center>


            <v-flex xs12 md6>

                <v-card flat style="width:80%;margin:0 auto;display:block;background:transparent;">
                    <v-card-text style="font-size:30px;color:#333;font-weight:600;text-align:center;
                    padding:25px 0px 20px 0;
                    ">
                        ¿Quienes Somos?
                    </v-card-text>
                    <v-card-text>
                        <p style="line-height:30px;
                        font-size:20px;width:90%;margin:0 auto;display:block;color:#3333336c">
                            Somos una empresa venezolana dedicada a la promoción y comercialización de bienes raíces, ofreciendo servicios integrales basándonos totalmente en las necesidades de nuestros clientes para brindarles la mejor asesoría inmobiliaria y mejorar su calidad de vida.
                        </p>
                    </v-card-text>
                </v-card>

            </v-flex>
            <v-flex xs12 md6>


                <v-card flat style="width:80%;margin:0 auto;display:block;background:transparent;">
                    <v-card-text style="font-size:30px;color:#333;font-weight:600;text-align:center;
                    padding:25px 0px 20px 0;
                    ">
                        Nuestra Misión
                    </v-card-text>
                    <v-card-text>
                        <p style="line-height:30px;
                        font-size:20px;width:90%;margin:0 auto;display:block;color:#3333336c">
Es la de brindar la mejor asesoría inmobiliaria tanto a nuestros clientes compradores sobre las diferentes alternativas de inversión, como a nuestros clientes vendedores sobre sus requerimientos, mediante un análisis comparativo GLOBAL que nos permita asesorarlos para las tomas de decisiones de acuerdo a sus necesidades comprometiéndonos al máximo con ellos basándonos en un ambiente de confianza, responsabilidad y profesionalismo.
</p>
                    </v-card-text>
                </v-card>


            </v-flex>


        </v-layout>


        <v-layout row justify-center>


            <v-flex xs12 md6>

                <v-card flat style="width:80%;margin:0 auto;display:block;background:transparent;">
                    <v-card-text style="font-size:30px;color:#333;font-weight:600;text-align:center;
                    padding:25px 0px 20px 0;
                    ">
                        Nuestra Visión
                    </v-card-text>
                    <v-card-text>
                        <p style="line-height:30px;
                        font-size:20px;width:90%;margin:0 auto;display:block;color:#3333336c">
Posicionarnos con unas de las mejores empresas en el ramo inmobiliario distinguiéndonos por nuestra atención oportuna y personalizada, respuesta inmediata y profesionalismo, logrando la total satisfacción de nuestros clientes.
</p>
                    </v-card-text>
                </v-card>

            </v-flex>

            <v-flex xs12 md6>

                <v-img height="350" src="https://www.segurodevidahipoteca.es/wp-content/uploads/2019/11/paralizar-hipoteca.jpg"></v-img>

            </v-flex>


        </v-layout>

        <div style="margin-top:100px;">
            <v-img src="@/assets/confianza.jpg" height="300"></v-img>
            <p class="text-center" style="padding-bottom:20px;padding-top:20px;font-size:30px;color:#333">
                Valores Global
            </p>
            <div style="margin:50px auto;display:block;width:50px;border-top:solid 4px #D41700"></div>
            <v-layout row justify-center>
                <v-flex xs12 md6>

                <v-card flat style="width:80%;margin:0 auto;display:block;background:white;">
                    <v-card-text style="font-size:30px;color:#333;font-weight:600;text-align:center;
                    padding:25px 0px 20px 0;
                    ">
                        Compromiso
                    </v-card-text>
                    <v-card-text>
                        <p style="line-height:30px;
                        font-size:20px;width:90%;margin:0 auto;display:block;color:#3333336c">
Somos un equipo donde prevalece el compromiso con nosotros mismos, con nuestros objetivos, metas y de allí parte nuestro segundo gran compromiso que es con nuestros colegas y clientes.</p>
                    </v-card-text>
                </v-card>

            </v-flex>
            <v-flex xs12 md6>

                <v-card flat style="width:80%;margin:0 auto;display:block;">
                    <v-card-text style="font-size:30px;color:#333;font-weight:600;text-align:center;
                    padding:25px 0px 20px 0;
                    ">
                        Confianza
                    </v-card-text>
                    <v-card-text>
                        <p style="line-height:30px;
                        font-size:20px;width:90%;margin:0 auto;display:block;color:#3333336c">
Merecemos la confianza de nuestro equipo, colegas y clientes, esto porque demostramos que cumplimos con lo ofrecido, acompañando nuestro trabajo con conocimiento y en el tiempo establecido para tal fin.</p>
                    </v-card-text>
                </v-card>

            </v-flex>


            <v-flex xs12 md6 class="my-5">

                <v-card flat style="width:80%;margin:0 auto;display:block;background:white;">
                    <v-card-text style="font-size:30px;color:#333;font-weight:600;text-align:center;
                    padding:25px 0px 20px 0;
                    ">
                        Congruencia
                    </v-card-text>
                    <v-card-text>
                        <p style="line-height:30px;
                        font-size:20px;width:90%;margin:0 auto;display:block;color:#3333336c">
Creemos congruentemente en los productos que ofrecemos, así como el capital humano que nos conforma.       </p>             </v-card-text>
                </v-card>

            </v-flex>

            <v-flex xs12 md6 class="my-5">

                <v-card flat style="width:80%;margin:0 auto;display:block;">
                    <v-card-text style="font-size:30px;color:#333;font-weight:600;text-align:center;
                    padding:25px 0px 20px 0;
                    ">
                        Honestidad
                    </v-card-text>
                    <v-card-text>
                        <p style="line-height:30px;
                        font-size:20px;width:90%;margin:0 auto;display:block;color:#3333336c">
Actuamos de manera adecuada y transparente. Reconocemos nuestros errores y trabajamos por corregirlos, así como reconocemos los aciertos de los demás.</p></v-card-text>
                </v-card>

            </v-flex>

            <v-flex xs12 md6 class="my-5">

                <v-card flat style="width:80%;margin:0 auto;display:block;background:white;">
                    <v-card-text style="font-size:30px;color:#333;font-weight:600;text-align:center;
                    padding:25px 0px 20px 0;
                    ">
                        Responsabilidad
                    </v-card-text>
                    <v-card-text>
                        <p style="line-height:30px;
                        font-size:20px;width:90%;margin:0 auto;display:block;color:#3333336c">
Somos cuidadosos al momento de tomar decisiones o dar opiniones a nuestros clientes y cumplimos plenamente con nuestras obligaciones.
</p></v-card-text>
                </v-card>

            </v-flex>

            <v-flex xs12 md6 class="my-5">

                <v-card flat style="width:80%;margin:0 auto;display:block;">
                    <v-card-text style="font-size:30px;color:#333;font-weight:600;text-align:center;
                    padding:25px 0px 20px 0;
                    ">
                        Eficacia y Rapidez
                    </v-card-text>
                    <v-card-text>
                        <p style="line-height:30px;
                        font-size:20px;width:90%;margin:0 auto;display:block;color:#3333336c">
Somos eficaces y rápidos en la ejecución de nuestras obligaciones y deberes. Damos respuesta inmediata a nuestros clientes, colegas y compañeros de trabajo.</p></v-card-text>
                </v-card>

            </v-flex>


            <v-flex xs12 md6 class="my-5">

                <v-card flat style="width:80%;margin:0 auto;display:block;background:white;">
                    <v-card-text style="font-size:30px;color:#333;font-weight:600;text-align:center;
                    padding:25px 0px 20px 0;
                    ">
                        Trabajo en Equipo
                    </v-card-text>
                    <v-card-text>
                        <p style="line-height:30px;
                        font-size:20px;width:90%;margin:0 auto;display:block;color:#3333336c">
Tenemos determinado, que solo a través de un verdadero trabajo en equipo es que podemos lograr los resultados que queremos.

</p></v-card-text>
                
                </v-card>

            </v-flex>

              <v-flex xs12 md6 class="my-5">

                <v-card flat style="width:80%;margin:0 auto;display:block;">
                    <v-card-text style="font-size:30px;color:#333;font-weight:600;text-align:center;
                    padding:25px 0px 20px 0;
                    ">
                        Progreso
                    </v-card-text>
                    <v-card-text>
                        <p style="line-height:30px;
                        font-size:20px;width:90%;margin:0 auto;display:block;color:#3333336c">
Creemos verdaderamente en el progreso de nuestro equipo y talento humano, es por ello que ofrecemos planes de crecimiento personal y profesional.
</p></v-card-text>
                
                </v-card>

            </v-flex>

              <v-flex xs12 md6 class="my-5">

                <v-card flat style="width:80%;margin:0 auto;display:block;background:white;">
                    <v-card-text style="font-size:30px;color:#333;font-weight:600;text-align:center;
                    padding:25px 0px 20px 0;
                    ">
                        Respeto
                    </v-card-text>
                    <v-card-text>
                        <p style="line-height:30px;
                        font-size:20px;width:90%;margin:0 auto;display:block;color:#3333336c">
Creemos en el respeto por el ser humano, por la individualidad, por el libre pensamiento.

</p></v-card-text>
                
                </v-card>

            </v-flex>

            <v-flex xs12 md6 class="my-5">

                <v-card flat style="width:80%;margin:0 auto;display:block;">
                    <v-card-text style="font-size:30px;color:#333;font-weight:600;text-align:center;
                    padding:25px 0px 20px 0;
                    ">
                        Constancia
                    </v-card-text>
                    <v-card-text>
                        <p style="line-height:30px;
                        font-size:20px;width:90%;margin:0 auto;display:block;color:#3333336c">
Tenemos la fuerza y enfoque que nos impulsa al logro de las metas que nos proponemos.
</p></v-card-text>
                
                </v-card>

            </v-flex>
            </v-layout>
            <v-img src="@/assets/respeto.jpg" height="300" style="margin-top:100px;"></v-img>
        </div>

    </div>

</template>



<style>
.dfd{
    color: #3333336c;
}

</style>

<script>
export default {
    data(){
        return {

        }
    }
}
</script>